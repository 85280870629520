body {
  margin: 0;
  background-color: whitesmoke;
}

* {
  box-sizing: border-box;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.headerContainer {
  height: 200px;
  width: 100vw;
  background-color: rgb(190, 3, 22);
  display: flow-root;

  .titleContainer {
    margin-top: 16px;

    display: flex;
    justify-content: center;
    align-items: center;

    .cover {
      height: 42px;
      width: 42px;
      border-radius: 10000px;
      overflow: hidden;
    }

    .cover > img {
      object-fit: cover;
    }

    .title {
      font-size: 22px;
      font-weight: bold;
      color: white;
      margin-left: 8px;
    }
  }

  .infoContainer {
    margin-top: 12px;
    font-size: 16px;
    color: white;

    display: flex;
    flex-wrap: wrap;
    padding-left: 20px;

    .infoItem {
      width: 49%;
      margin-top: 12px;

      .itemName {
        font-size: 14px;
      }

      .itemVal {
        display: inline-block;
        margin-left: 8px;
        font-size: 16px;
        font-weight: bolder;
      }
    }
  }
}

.card {
  width: calc(100vw - 32px);
  margin: 0 16px;
  padding: 16px;
  background-color: white;
  border-radius: 6px;
  box-shadow: 3px 2px 6px 2px rgb(178 178 178 / 16%);
  font-size: 14px;
}

.passCard {
  margin-top: -48px;
  display: flex;
  align-items: center;
  flex-direction: column;

  .passTitle {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    text-align: center;
  }

  .passTime {
    margin-top: 12px;
    text-align: center;
    color: rgb(190, 3, 22);
    font-size: 14px;
    font-family: Helvetica, sans-serif;
  }

  .passIcon {
    margin-top: 16px;
    width: 80px;
    height: 80px;
    object-fit: cover;
  }

  .passDes {
    margin-top: 8px;
    font-size: 18px;
    font-weight: bold;
    color: #34a853;
  }
}

.locationCard {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-size: 16px;
    font-weight: bolder;
  }

  .direction {
    color: rgb(190, 3, 22);
    font-size: 14px;
  }
}

.timeCard > p {
  margin: 0;
  font-size: 14px;
}
